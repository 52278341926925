import React, { useEffect, useRef, useState } from "react";
import { CheckApiError, GetGeneric } from "../common/teide";
import { useNavigate } from "react-router-dom";
import HorizontalRangeSelector, { IHorizontalItem } from "../components/HorizontalRangeSelector";
import TriState from "../components/TriState";
import PleaseWaitButton from "../components/PleaseWaitButton";
import BiState from "../components/BiState";

interface Map {
    [key: string]: string | undefined
}

interface IPotential extends IHorizontalItem {
    artistCount: number;
    artistId: string;
}

type GenreItem =
    {
        id: string,
        name: string,
        genres: string[],
        topSongsOnly: boolean
    }

export default function CreatePlaylist() {
    const navigate = useNavigate();
    const tri = useRef<HTMLDivElement>(null);
    const [genreData, setGenreData] = useState<IHorizontalItem[]>();
    const persisted = useRef<Map>({ smartavoidsamesong: '1' });

    useEffect(() => {
        GetGeneric(`/PlayLists/GenreSummary`).then(results => {
            if (CheckApiError(navigate, results)) return;
            const interim: Record<string, GenreItem[]> = {};
            (results as GenreItem[]).forEach(element => {
                element.genres.forEach(g => {
                    if (interim[g] == undefined) interim[g] = [];
                    interim[g].push(element);
                })
            });
            const genreItems: IPotential[] = [];
            Object.keys(interim).
                sort((x, y) => {
                    const diff = interim[y].length - interim[x].length
                    if (diff !== 0) return diff;
                    return x.localeCompare(y);
                }).
                forEach(x => {
                    const sorted = interim[x].sort((x, y) => x.name.localeCompare(y.name));
                    const highRatedArtists = sorted.filter(f => f.topSongsOnly === false).map(y => y.name).join('\r\n')
                    const lowRatedArtists = sorted.filter(f => f.topSongsOnly === true).map(y => y.name).join('\r\n')
                    genreItems.push({ artistId: interim[x][0].id, artistCount: interim[x].length, key: x, selected: false, text: x + ' (' + interim[x].length.toString() + ')', tooltip: highRatedArtists + "\n\nJust top songs\r\n\r\n" + lowRatedArtists });
                });
            setGenreData(genreItems);
        })
    }, [])

    const currentYear = new Date().getFullYear();
    const currentDecade = Math.floor((currentYear / 10)) * 10;
    const decadeSelection: IHorizontalItem[] = [];
    for (let year = 1950; year <= currentYear; year += 10) {
        decadeSelection.push({ key: (year / 10).toString(), selected: false, text: (year.toString()) + "s" });
    }

    for (let year: number = currentDecade + 1; year <= currentYear; year += 1) {
        decadeSelection.push({ key: year.toString(), selected: false, text: (year.toString()) });
    }

    const songLength: IHorizontalItem[] = [];
    for (let duration = 1; duration < 20; duration++) {
        songLength.push({ className: 'song-length', key: duration.toString(), selected: false, text: duration.toString() });
    }
    const maxLength = JSON.parse(JSON.stringify(songLength));

    const playlistLength: IHorizontalItem[] = [];
    for (let length = 10; length < 50; length += 5) {
        playlistLength.push({ key: length.toString(), selected: (length == 25), text: length.toString() })
    }

    function UpdateData(name: string, allItems: string) {
        persisted.current[name] = allItems;
        const stringVal = JSON.stringify(persisted.current);
        persisted.current = JSON.parse(stringVal);
    }

    let genres
    if (genreData !== undefined) {
        genres = <>
            <div className="row"><h4 className="col">Choose Genres for your playlist. Hover your mouse over the genres to see which artists are included</h4></div>
            <HorizontalRangeSelector itemLimit={{ max: 16, showAllButton: true }} clearButtonText="Clear Genre Selection" multiSelect={true} onChange={UpdateData} name="genre" items={genreData}></HorizontalRangeSelector>
        </>
    }
    else{
        genres= <h3>Loading Genres</h3>
    }
    return <div className="row">
        <div className="scrollable">
            <br></br>
            {genres}
            <br></br>
            <div className="row"><div className="col">Choose Decade (recent year)</div></div>
            <div className="row"><div className="col">
                <HorizontalRangeSelector name="decade" onChange={UpdateData} multiSelect={true} items={decadeSelection}></HorizontalRangeSelector>
            </div></div>
            <br></br>
            <div className="row"><div className="col">Minimum Song Duration (minutes)</div></div>
            <div className="row"><div className="col">
                <HorizontalRangeSelector name="minLength" onChange={UpdateData} multiSelect={false} items={songLength}></HorizontalRangeSelector>
            </div></div>
            <br></br>
            <div className="row"><div className="col">Maximum Song Duration (minutes)</div></div>
            <div className="row"><div className="col">
                <HorizontalRangeSelector name="maxLength" onChange={UpdateData} multiSelect={false} items={maxLength}></HorizontalRangeSelector>
            </div></div>

            <br></br>
            <div className="row">
                <div className="col-3 my-auto">Remixes</div>
                <div ref={tri} className="col"><TriState onChange={UpdateData} name="remix" default="all" ></TriState>
                </div></div>

            <div className="row">
                <div className="col-3 my-auto">Live Songs</div>
                <div className="col"><TriState onChange={UpdateData} name="live" default="exclude"></TriState>
                </div></div>

            <div className="row">
                <div className="col-3 my-auto">Christmas Songs</div>
                <div className="col"><TriState onChange={UpdateData} name="xmas" default="exclude"></TriState>
                </div></div>

            <div className="row">
                <div className="col-3 my-auto">Instrumental</div>
                <div className="col"><TriState onChange={UpdateData} name="instrumental" default="all"></TriState>
                </div></div>

            <div className="row">
                <div className="col-3 my-auto">Remastered</div>
                <div className="col"><TriState onChange={UpdateData} name="remastered" default="all"></TriState>
                </div></div>

            <div className="row">
                <div className="col-3 my-auto">Deluxe Edition</div>
                <div className="col"><TriState onChange={UpdateData} name="deluxe" default="all"></TriState>
                </div>
            </div>
            <div className="row">
                <div className="col-3 my-auto">Collaboration</div>
                <div className="col"><TriState onChange={UpdateData} name="collaboration" default="all"></TriState>
                </div>
            </div>
            <div className="row">
                <div className="col-3 my-auto">Secondary Artist</div>
                <div className="col"><TriState onChange={UpdateData} name="secondaryartist" default="all"></TriState>
                </div>
            </div>

            <div className="row">
                <div className="col-3 my-auto">Prevent Same Song (smart)</div>
                <div className="col"><BiState onChange={UpdateData} name="smartavoidsamesong" default="yes" ></BiState>

                    {/* <div className="col"><input type="checkbox" onChange={UpdateInput} id="smartavoidsamesong" name="smartavoidsamesong" ></input> */}
                </div>
            </div>

            <div className="row">
                <div className="col-3 my-auto">Sped Up/Slowed Down</div>
                <div className="col"><TriState onChange={UpdateData} name="spedslow" default="exclude"></TriState>
                </div>
            </div>

            <br></br>
            <div className="row"><div className="col">Songs in playlist</div></div>
            <div className="row"><div className="col">
                <HorizontalRangeSelector name="songsInList" onChange={UpdateData} multiSelect={false} items={playlistLength}></HorizontalRangeSelector>
            </div></div>
            <br></br>
            <div>
                <PleaseWaitButton buttonText="Build Playlist" waitText="Please wait, this can take a long time (up to a few minutes depending on how many artists you follow)" onClick={WorkTheMagic} className="btn btn-primary"></PleaseWaitButton>
            </div>
            <br></br>
            <br></br>
        </div>
    </div>


    function WorkTheMagic() {
        navigate('/proposed-playlist', { state: { params: persisted.current } })
    }

    return <div>
        Loading Genres / Options
    </div>
}