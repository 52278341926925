import React, { useEffect, useRef, useState } from "react";
import { CallServerPrivate, PostGeneric } from '../common/teide';
import '../css/Security.css';
import { useNavigate } from 'react-router-dom';
import PleaseWaitButton from "../components/PleaseWaitButton";

export default function Login() {
    useEffect(() => {
        if (emailPrompt.current !== null) emailPrompt.current.focus();
    }, [])

    const emailPrompt = useRef<HTMLInputElement>(null);
    //const errorArea = useRef<HTMLSpanElement>(null);
    const submitForm = useRef<HTMLFormElement>(null);
    const [errorMessage, setErrorMessage] = useState<string>("")

    const navigate = useNavigate();

    async function LoginUser() {
        //e.preventDefault();
        if (submitForm.current == null) return
        const formData = new FormData(submitForm.current);
        const creds = { username: formData.get('email'), password: formData.get('pass') };
        //const result = await PostGeneric('/Authenticate/GetToken', JSON.stringify(creds));
        CallServerPrivate('/Authenticate/GetToken', 'POST', JSON.stringify(creds), false).then(result => {
            if (result.status === 401) {
                setErrorMessage("Invalid Login, username or password not recognised");
            }
            else if (result.status === 500) {
                setErrorMessage("Internal Server Error")
            }
            else if (result.status === 404) {
                setErrorMessage("Unable to connect to server")
            }
            else if (result.status == 200) {
                localStorage.setItem("spot-token", result.body.token);
                navigate('/welcome');
            }
            else {
                setErrorMessage("Unknown Error " + result.status)
            }
        }).catch((err: any)=>{
            console.error(err.message)
            setErrorMessage("Dodgy Error "+err.message)
        })
    }

    return <div className="row">
        <div className="col"></div>
        <div className="container-flow login-wrap">
            <div className="row">
                <div className='header text-center'>Please log in to LetTheMusicPlay</div>
            </div>
            <br></br>
            <div className="row">
                <div className='green header text-center'>This is NOT your Spotify login details</div>
            </div>
            <br></br>

            <div className="">
                <form ref={submitForm} className="sign-in-htm" /*onSubmit={LoginUser}*/ noValidate>
                    <div className="group">
                        <label htmlFor="user" className="label">Email Address</label>
                        <input ref={emailPrompt} name="email" id="email" type="email" className="input" autoComplete='username' />
                    </div>
                    <div className="group">
                        <label htmlFor="pass" className="label">Password</label>
                        <input name="pass" id="pass" type="password" className="input" autoComplete='current-password' />
                    </div>
                    <div className="group">
                        <PleaseWaitButton /*formForSubmit={hasForm ? submitForm.current!: undefined}*/ onClick={LoginUser} buttonText="Sign In" waitText="Please wait" className="btn btn-primary" />
                    </div>
                    <div className="hr"></div>
                    <div className="foot-lnk">
                        <a href="#forgot">Forgot Password?</a>
                    </div>
                    <div className="group">
                        <span className='header red'>{errorMessage}</span>
                    </div>

                </form>
            </div>
        </div>
        <div className="col"></div>
    </div>
}