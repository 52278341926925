import React from "react";
import HorizontalRangeSelector, {IHorizontalItem} from "./HorizontalRangeSelector";

interface IProps
{
    name: string
    default: 'yes' | 'no'
    onChange: (name: string,selectedItems: string)=>void
}

export default function BiState(props: IProps)
{
    function BubbleUp(name: string,selectedItems: string)
    {
        const useSelected=selectedItems==='all'?'':selectedItems;
        props.onChange(name,useSelected);
    }

    const items:IHorizontalItem[] = [
        {key:'yes',selected: (props.default=='yes'),text:"Yes"},
        {key:'no',selected: (props.default=='no'),text:"No"},
    ]
    return <>
        <HorizontalRangeSelector name={props.name} onChange={BubbleUp} items={items} multiSelect={false}></HorizontalRangeSelector>
    </>
    
}