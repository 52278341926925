import { NavigateFunction } from "react-router-dom";

export const urlPrefix = process.env.REACT_APP_API_PATH;
function GetToken(): string | null {
  const useToken = localStorage.getItem("spot-token");
  if (useToken === 'undefined') return null;
  return useToken;
}

interface IError {
  error: string;
}

type LoginResponse = {
  loggedIn: boolean
  spotifyLinked: boolean
  username: string
}

export function CheckApiError(navigate: NavigateFunction | undefined, response: IError): boolean {
  if (response.error !== undefined) {
    const err = response.error;
    if (navigate !== undefined) navigate('/error', { state: { err } });
    return true;
  }
  return false;
}

export function GetLoggedInStatus(): LoginResponse {
  const hasToken = GetToken();
  if (hasToken === null) return { loggedIn: false, spotifyLinked: false, username: '' };
  const tokenInfo = DecodeToken(hasToken);
  if (tokenInfo === null) return { loggedIn: false, spotifyLinked: false, username: '' };
  const dt = new Date().getTime() / 1000;
  const expiryMinutes = (tokenInfo.exp - dt) / 60;
  if (expiryMinutes < 30) {
    return { loggedIn: false, spotifyLinked: false, username: '' }; // if there's less than 30 mins remaining, log them out
  }

  if (tokenInfo.spot_info === '1') return { loggedIn: true, spotifyLinked: true, username: tokenInfo.sub };
  return { loggedIn: true, spotifyLinked: false, username: tokenInfo.sub };
}

async function CallFetch(apiPost: string, callInfo: RequestInit) {
  return await fetch(apiPost, callInfo)
    .then(resp => {
      const contentType = resp.headers.get("content-type");
      if (contentType != null && contentType.startsWith('application/json')) return resp.json();
      else if (contentType != null && contentType.startsWith('application/problem+json')) return resp.json();
      else if (contentType?.startsWith('image')) return resp.arrayBuffer();
      else return resp.text();
    })
    .then(txt => {
      return txt;
    })
    .catch((e) => {
      return { error: e.message }
    });
}

export type ApiResponse = {
  status: number
  body: any
}

export async function CallServerPrivate(url: string, method: 'GET' | 'POST', body: BodyInit | null, ignoreResponseBody: boolean) {
  // if (!url.startsWith("http")) {
  //     const prefix = process.env.URL_PREFIX
  //     if (prefix == null || prefix.length == 0) throw { message: "Missing URL_PREFIX" }
  //     url = prefix + url
  // }
  console.log("calling", method, url)
  return new Promise<ApiResponse>((resolve, reject) => {
    const headers: Record<string, string> = { 'Content-Type': 'application/json', 'cache-control': 'no-cache' }

    const requestDetails: RequestInit = {
      body: body,
      method: method,
      cache: 'no-store',
      headers: headers
    }
    fetch(urlPrefix + url, requestDetails).then(res => {
      if (res.status == 204) {
        // This just means no data or record update, so it's still valid, but no data is returned
        console.log("got status of 204")
        resolve({ status: res.status, body: null })
        return
      }
      if (res.ok) {
        if (ignoreResponseBody) {
          resolve({ status: res.status, body: null })
          return
        }
        res.json().then(j => {
          resolve({ status: res.status, body: j })
        }).catch(err => {
          console.error("json get", err.message)
          reject(err)
        })
      }
      else {
        const contentType = res.headers.get('content-type')
        if (contentType?.startsWith('text')){
          res.text().then(txt => {
            if (res.status == 500) reject(txt) // only reject if it's a server error (500)
            resolve({ status: res.status, body: txt })
          })
        }
        else if (contentType?.startsWith('json')){
          res.json().then(j => {
            if (res.status == 500) reject(j) // only reject if it's a server error (500)
            resolve({ status: res.status, body: j })
          })
        }
        else{
          console.log("info", res.statusText, contentType)
          resolve({ status: res.status, body: null })
        }
      }
    }).catch(err => {
      console.error("fetch error", err.message)
      reject(err)
    })

  })
}

export async function GetGeneric(urlSuffix: string) {
  const token = GetToken();
  const callInfo: RequestInit = { method: 'GET', mode: 'cors', cache: 'no-store', headers: { 'Authorization': 'Bearer ' + token, 'cache-control': 'no-cache' } };
  const res = await CallFetch(urlPrefix + urlSuffix, callInfo);
  return res;
}

export async function PostGeneric(urlSuffix: string, body: BodyInit | null) {
  const token = GetToken();
  const callInfo: RequestInit = { method: 'POST', mode: 'cors', 'body': body, cache: 'no-store', headers: { 'Authorization': 'Bearer ' + token, 'cache-control': 'no-cache', 'Content-Type': 'application/json' } };
  const res = await CallFetch(urlPrefix + urlSuffix, callInfo);
  return res;

}

export async function PutGeneric(urlSuffix: string, body: BodyInit | null) {
  const token = GetToken();
  const callInfo: RequestInit = { method: 'PUT', mode: 'cors', 'body': body, cache: 'no-store', headers: { 'Authorization': 'Bearer ' + token, 'cache-control': 'no-cache', 'Content-Type': 'application/json' } };
  const res = await CallFetch(urlPrefix + urlSuffix, callInfo);
  return res;
}

export async function DeleteGeneric(urlSuffix: string, body: BodyInit | null) {
  const token = GetToken();
  const callInfo: RequestInit = { method: 'DELETE', mode: 'cors', 'body': body, cache: 'no-store', headers: { 'Authorization': 'Bearer ' + token, 'cache-control': 'no-cache', 'Content-Type': 'application/json' } };
  const res = await CallFetch(urlPrefix + urlSuffix, callInfo);
  return res;

}

type TokenInfo = {
  spot_info: '0' | '1' // whether there's a token or not
  sub: string // The username
  exp: number // expiry MS
}

export function DecodeToken(token: string | null): TokenInfo | null {
  if (token === null) return null;
  const elems = token.split('.');
  if (elems.length !== 3) return null;
  let content = elems[1];
  content = content.replace(/-/g, '+').replace(/_/g, '/');
  const fred = window.atob(content);
  const ret = JSON.parse(fred);
  return ret;
}

export function ExpandURL(baseUrl: string, params: object) {
  let url = baseUrl + '?';
  Object.entries(params).forEach((k) => {
    url += k[0] + '=' + k[1] + '&';
  });
  return url;
}

export function ToBase64(str: string) {
  return btoa(str);
}