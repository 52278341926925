import React, { useEffect, useRef, useState } from "react";
import PleaseWaitButton from "../components/PleaseWaitButton";
import ProposedSong, { ISongDetails } from "../components/ProposedSong";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckApiError, PostGeneric } from "../common/teide";
import ThreeDWait from "../components/ThreeDWait";

export default function ProposedPlaylist() {
    const navigate = useNavigate();
    const location = useLocation();
    const [playlistData, setPlaylistData] = useState<ISongDetails[]>();

    if (location.state === null) {
        return <div>Error</div>
    }
    const params = location.state.params;
    const waitContainer = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (playlistData == null) {
            CreatePlaylist();
        }
    }, [])

    async function CreatePlaylist() {
        if (params["songsInList"] == "") return;
        if (params["minLength"] == "") params["minLength"] = undefined;
        if (params["maxLength"] == "") params["maxLength"] = undefined;
        params["smartavoidsamesong"] = (params["smartavoidsamesong"] == 'yes')
        console.log("params", params)
        PostGeneric(`/PlayLists/GeneratePlaylist`, JSON.stringify(params)).then(results => {
            console.log("results", results)
            if (CheckApiError(navigate, results)) return;
            setPlaylistData(results);
        }).catch(err => {
            console.error(err)
        })
    }

    async function produceList() {
        if (playlistData === undefined) return;
        const xxx: string[] = [];
        playlistData.forEach(e => {
            if (e.exclude) return;
            xxx.push(e.track_id);
        });
        const body = JSON.stringify(xxx);
        const results = await PostGeneric('/PlayLists/SavePlaylist', body);
        if (CheckApiError(navigate, results)) return;
    }

    if (playlistData !== undefined) {
        const playlistInfo = playlistData.map(e => {
            return <ProposedSong key={e.track_id} song={e}></ProposedSong>
        });

        return <div ref={waitContainer} className="row">
            <div className="scrollable pb-5">
                <div className="row p-3">
                    <PleaseWaitButton finishedText="Done, please view/play this in Spotify" finishedAction="hide" buttonText="Produce this playlist in Spotify" waitText="Building" className="col-auto m-2 btn btn-success" onClick={produceList}></PleaseWaitButton>
                    <button className="m-2 btn btn-primary col-auto" onClick={ChangeCriteria}>Back to create new playlist</button>
                    <button className="m-2 btn btn-primary col-auto" onClick={() => navigate('/welcome')}>Back to Home</button>
                </div>
                {playlistInfo}
            </div>
        </div>
    }
    else {
        return <div ref={waitContainer}>
            <h2>Producing Playlist, please wait, this can take a while, especially if this is your first playlist</h2>
            <ThreeDWait></ThreeDWait>
        </div>
    }

    function ChangeCriteria() {
        navigate('/gen-playlist');
    }
}